(function () {
    'use strict';

    if (!$('.pg-home').length > 0) return false;

    $(document).ready(function () {
        accordion.init({ speed: 300, oneOpen: true });

        $('#hero-slider').on('init', function (e, slick) {
            var $firstAnimatingElements = $('div.hero-slide:first-child').find('[data-animation]');
            doAnimations($firstAnimatingElements);
        });
        $('#hero-slider').on('beforeChange', function (e, slick, currentSlide, nextSlide) {
            var $animatingElements = $('div.hero-slide[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
            doAnimations($animatingElements);
        });
        $('#hero-slider').slick({
            autoplay: true,
            autoplaySpeed: 10000,
            dots: false,
            fade: true
        });

        function doAnimations(elements) {
            var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
            elements.each(function () {
                var $this = $(this);
                var $animationDelay = $this.data('delay');
                var $animationType = 'animated ' + $this.data('animation');
                $this.css({
                    'animation-delay': $animationDelay,
                    '-webkit-animation-delay': $animationDelay
                });
                $this.addClass($animationType).one(animationEndEvents, function () {
                    $this.removeClass($animationType);
                });
            });
        }

        $('.slider-advogados').slick({
            infinite: false,
            dots: false,
            rows: 2,
            slidesPerRow: 3,
            prevArrow: $('.slider-prev'),
            nextArrow: $('.slider-next')
        });

    });
    
})();