var url = window.location.protocol + "//" + window.location.host;

var viewport = {
    w: $(window).width(),
    h: $(window).height()
};

$(window).resize(function () {
    viewport = {
        w: $(window).width(),
        h: $(window).height()
    };
});

var isMobile = function () {
    return (viewport.w < 768);
};

var isTablet = function () {
    return (viewport.w >= 768 && viewport.w <= 1024);
};

var isDesktop = function () {
    return (viewport.w > 1024);
};

(function ($) {
    var oAddClass = $.fn.addClass;
    $.fn.addClass = function () {
        for (var i in arguments) {
            var arg = arguments[i];
            if ( !! (arg && arg.constructor && arg.call && arg.apply)) {
                setTimeout(arg.bind(this));
                delete arguments[i];
            }
        }
        return oAddClass.apply(this, arguments);
    };

})(jQuery);

function msieVersion() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0) /* If Internet Explorer, return version number */
        return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)));
    else /* If another browser, return 0 */
        return 0;
}
function ie() {
    var ua = window.navigator.userAgent,
      msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }
    return false;
}

function ancora(){
    var off;
    var $doc = $('html, body');
    $('.ancora').click(function(e) {
        off = $(this).data('ancora') ? $(this).data('ancora') : 0;

        $doc.animate({
            scrollTop: $( $.attr(this, 'href') ).offset().top - off
        }, 800);
        return false;
    });
}

function youtube(){
        
    var boxVideo = $(".youtube_video");
    var idVideo = boxVideo.data('video').replace("https://www.youtube.com/watch?v=", "");

    var urlImage = "https://img.youtube.com/vi/" + idVideo + "/hqdefault.jpg";
    var img = new Image();
    img.src = urlImage;
    img.alt = "Imagem em miniatura do vídeo";

    boxVideo.append(img);
    boxVideo.on("click", function () {

        var iframe = document.createElement("iframe");

        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allowfullscreen", "");
        iframe.setAttribute("src", "https://www.youtube.com/embed/" + idVideo + "?rel=0&showinfo=0&autoplay=1");
        $(this).html('');
        $(this).append(iframe);
    });
}


function menu() {
    var altura = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;

    $(".link-menu").click(function() {
        if(!$(this).hasClass("aberto")) {
            $(this).addClass("aberto");
            $(".menu").slideDown(600);
        }
        else {
            $(this).removeClass("aberto");
            $(".menu").slideUp(600);
        }
    });

    var lastId,
        topMenu = $("header"),
        menuItems = topMenu.find(".menu li a").not('.not');
    var scrollItems = menuItems.map(function() {
        var item = $(this).attr("href");
        if ($(item).length) {
            return $(item);
        }
    });


    var noScrollAction = false;
    menuItems.click(function(e) {

        if ($(window).width() < 1024 && $('.link-menu').hasClass('open')) {
            $('.link-menu').trigger('click');
        }
        var href = $(this).attr("href"),
            offsetTop = href === "#" ? 0 : $(href).offset().top;

            if (!isDesktop()) {
                offsetTop = href === "#" ? 0 : $(href).offset().top - 40;
                $(".link-menu").removeClass("aberto");
                $(".menu").slideUp();
            }

        noScrollAction = true;
        $('html, body').stop().animate({
            scrollTop: offsetTop
        }, {
            duration: 300,
            complete: function() {

                menuItems.removeClass("ativo").end();
                menuItems.filter("[href=" + href + "]").addClass("ativo");
                setTimeout(function() {
                    noScrollAction = false;
                }, 10);
            }
        });
    });

    $(window).scroll(function() {
        if (!noScrollAction) {
            var fromTop = $(this).scrollTop();
            var cur = scrollItems.map(function() {
                if ($(this).offset().top-1 < fromTop)
                    return this;
            });

            cur = cur[cur.length - 1];
            var id = cur && cur.length ? cur[0].id : "";

            if (lastId !== id) {
                lastId = id;
                menuItems.removeClass("ativo").end();
                menuItems.filter("[href=#" + id + "]").addClass("ativo");
            }
        }
    });
}


function loadIn(){
    $('.loading').fadeIn();
}
function loadOut(){
    $('.loading').fadeOut();
}

var accordion = (function(){
  
    var $accordion = $('.js-accordion');
    var $accordion_header = $accordion.find('.js-accordion-header');
    var $accordion_item = $('.js-accordion-item');
   
    // default settings 
    var settings = {
      // animation speed
      speed: 400,
      
      // close all other accordion items if true
      oneOpen: false
    };
      
    return {
      // pass configurable object literal
      init: function($settings) {
        $accordion_header.on('click', function() {
          accordion.toggle($(this));
        });
        
        $.extend(settings, $settings); 
        
        // ensure only one accordion is active if oneOpen is true
        if(settings.oneOpen && $('.js-accordion-item.active').length > 1) {
          $('.js-accordion-item.active:not(:first)').removeClass('active');
        }
        
        // reveal the active accordion bodies
        $('.js-accordion-item.active').find('> .js-accordion-body').show();
      },
      toggle: function($this) {
              
        if(settings.oneOpen && $this[0] != $this.closest('.js-accordion').find('> .js-accordion-item.active > .js-accordion-header')[0]) {
          $this.closest('.js-accordion')
                 .find('> .js-accordion-item') 
                 .removeClass('active')
                 .find('.js-accordion-body')
                 .slideUp()
        }
        
        // show/hide the clicked accordion item
        $this.closest('.js-accordion-item').toggleClass('active');
        $this.next().stop().slideToggle(settings.speed);
      }
    }
  })();